.gp-iframe {
  border: 1px solid #9ea5b8;
}

#htmlSingleInvoice {
  border: 1px solid grey;
  box-shadow: 3px 2px 4px grey;
  padding: 18px;
  margin:20px;

  #howtopay {
    display: none;
  }
}

.paymentStatusDescriptor {
  margin-top:20px;
  margin-bottom:20px;
  padding:10px;
}

.invoiceBody {
  margin-top: 90px;
}

.prominent-text {
  font-size:1.3em;
}

// hides unprofessional looking title / printed at etc.
@page {
  size: auto;
  margin: 0cm 2.54cm; // keep the horizontal to 1 inch minimum as below this some printers cut off
}

@media print {
  body * {
    visibility: hidden;
  }
  #htmlSingleInvoice, #htmlSingleInvoice * {
    visibility: visible;
  }
  #htmlSingleInvoice {
    position: absolute; // stops ugly page run over
    left: 0;
    top: -160px; // take into account tapestry logo and button layer
    padding: 0px;
    box-shadow: none !important;
    border: none !important;
  }
}

.padding-md { // Bootstrap 4 class
  padding: 1em;
}

.payment-information {
  letter-spacing: 0.75px;
}

.paywrapper b {
  color: #0e731d;
  font-size: 120%;
}

#loginandpayscreenshot {
  width: 90%;
}
